import { Select } from '@components/Select';
import { Controller, FieldValues } from 'react-hook-form';
import FormContainer from './FormContainer';

// type FormSelectProps<TFieldValues> = Omit<
// 	IFormControlBase<ComponentProps<typeof Select>, TFieldValues>,
// 	'defaultValue'
// > & { items: ItemSelect[]; defaultValue?: any; disabled?: boolean };

const FormSelect = ({
	control,
	name,
	containerProps,
	controlProps,
	defaultValue,
	label,
	rules,
	className,
	isRequired,
	items,
	helper,
	disabled
}: FieldValues) => {
	return (
		<Controller
			name={name}
			defaultValue={defaultValue}
			rules={rules}
			control={control}
			render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
				<FormContainer
					helper={helper}
					error={error?.message}
					className={className}
					label={label}
					isRequired={typeof rules?.required == 'boolean' ? rules?.required : isRequired}
					{...containerProps}
				>
					<Select
						onChangeSelected={onChange}
						items={items}
						valueSelected={value}
						disabled={disabled}
						{...{ ...controlProps, ...field }}
					/>
				</FormContainer>
			)}
		/>
	);
};

export default FormSelect;
