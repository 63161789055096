import { Label2 } from '@pixiepkg/react';
import classNames from 'classnames';
import { ComponentProps, FC } from 'react';
import styles from './styles/FormControl.module.scss';

type FormContainerProps = ComponentProps<'div'> & {
	label?: string | JSX.Element;
	className?: string;
	isRequired?: boolean;
	helper?: string;
	error?: string;
};

const FormContainer: FC<FormContainerProps> = ({
	className,
	isRequired,
	label,
	children,
	helper,
	error,
	...props
}) => {
	return (
		<div className={classNames(styles.container, className)} {...props}>
			{typeof label === 'string' ? (
				<Label2 className={classNames(styles.label, { [styles.required]: isRequired })}>
					{label}
				</Label2>
			) : (
				label
			)}

			<div className={styles.control}>{children}</div>
			{!error && helper && <p className={styles.helper}>{helper}</p>}
			{error && <p className={styles.error}>{error}</p>}
		</div>
	);
};

export default FormContainer;
