import { ImageProps } from 'next/image';
import { SCREEN_SIZES, useWindowSize } from './useWindownSize';

interface UseImageBlurProps {
	placeholder?: 'blur' | 'empty';
	blurDataURL?: ImageProps['blurDataURL'];
}

export const useImageBlurProps = (): UseImageBlurProps => {
	const { width } = useWindowSize();

	return width < SCREEN_SIZES.xs
		? {}
		: {
				placeholder: 'blur',
				blurDataURL:
					'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
		  };
};
