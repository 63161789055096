import { ComponentProps } from 'react';
import {
	Control,
	FieldPath,
	FieldPathValue,
	FieldValues,
	RegisterOptions,
	UnpackNestedValue
} from 'react-hook-form';

export { default as FormInput } from './FormInput';
export { default as FormSelect } from './FormSelect';
export { default as FormTextArea } from './FormTextArea';
export { default as FormContainer } from './FormContainer';

export type IFormControlBase<
	TControlProps = any,
	TFieldValues extends FieldValues = FieldValues,
	TContext extends object = object,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
	containerProps?: ComponentProps<'div'>;
	controlProps?: TControlProps;
	control: Control<TFieldValues, TContext>;
	rules?: Omit<
		RegisterOptions<TFieldValues, TName>,
		'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
	>;
	name: TName;
	defaultValue?: UnpackNestedValue<FieldPathValue<TFieldValues, TName>>;
	label?: string | JSX.Element;
	className?: string;
	isRequired?: boolean;
	helper?: string;
	placeholder?: string;
};
