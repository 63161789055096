import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FormInput } from '@components/FormControl';
import { get, useForm } from 'react-hook-form';
import { Body3, Button, H3, H4, KindButton, Label1, Label2, SizeButton } from '@pixiepkg/react';
import { ArrowRight2Outline, CloseOutline } from '@pixiepkg/icon';
import { ExpertCard } from '@components/ExpertCard';
import Banner from './images/banner.png';
import Image from 'next/legacy/image';
import { AcuteSolid } from '@components/Icon';
import { api } from '@api';
import { Profile } from '@shared/models/Profile.model';
import { toast } from 'react-toastify';
import router from 'next/router';
import Link from 'next/link';
import { useSendEvent } from '@shared/hooks/useSendEvent';
import { ExpertCardV2 } from '@components/ExpertCardV2';

type IForm = {
	name: string;
	email: string;
	phone: string;
	question: string;
	extra: {
		reason: string;
	};
};

type Props = {
	search: string;
	isOpen: boolean;
	disableClickOutsideToClose?: boolean;
	onClose: () => void;
	compactMode?: boolean;
	hideForm?: boolean;
	showFooter?: boolean;
	openNewTab?: boolean;
	gradient?: boolean;
	onClickExpert?: (id: string) => void;
	onClickExpertBookNow?: (id: string) => void;
	onClickSeeAll?: () => void;
	clickedFrom?:
		| 'homepage'
		| 'expert_recommend'
		| 'before_webinar'
		| 'in_webinar'
		| 'after_webinar'
		| 'about_expert_recommend';
};

const RecommentExpretModal: FC<Props> = ({
	search,
	isOpen,
	onClose,
	disableClickOutsideToClose,
	compactMode,
	hideForm,
	showFooter,
	openNewTab,
	gradient,
	onClickExpert,
	onClickExpertBookNow,
	onClickSeeAll,
	clickedFrom
}) => {
	const { control, formState, handleSubmit } = useForm<IForm>();
	const [isSummited, setIsSummited] = useState(false);
	const [expretList, setExpretList] = useState<Profile[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const height = useRef<number>(0);
	const { sendEventGAAndBackend } = useSendEvent();

	useEffect(() => {
		const _model = document.getElementById('model');
		if (_model) {
			height.current = _model.clientHeight;
		}
	}, [isOpen]);

	const onSubmit = async (data: IForm) => {
		sendEventGAAndBackend('click_home_search_submit', {
			search_content: search,
			name: data.name,
			email: data.email,
			phone: data.phone,
			reason: data.question
		});
		try {
			await api.surveyExpert({
				...data,
				question: search
			});
			setIsSummited(true);
		} catch (err) {
			toast.error("Can't send survey");
		}
	};

	const handleGoToExpertList = () => router.push('/experts');

	const _renderExpret = () => {
		return (
			<div className="-mx-4 my-4 flex flex-wrap justify-center px-4">
				{isLoading &&
					Array(3)
						.fill({})
						.map((_, index) => (
							<div key={index} className="m-4">
								<ExpertCard
									loading
									key={index}
									compactMode={compactMode}
									openNewTab={openNewTab}
								/>
							</div>
						))}

				{!isLoading &&
					expretList.slice(0, 3).map((expret, index) => (
						<div key={index} className="m-4">
							<ExpertCardV2
								clickedFrom={clickedFrom}
								expert={expret}
								openNewTab={openNewTab}
								onClick={onClickExpert}
								onClickBookNow={onClickExpertBookNow}
							/>
						</div>
					))}
			</div>
		);
	};

	useEffect(() => {
		if (isOpen) {
			(async () => {
				try {
					setIsSummited(false);
					setIsLoading(true);
					const res = await api.searchExperts(search);
					setExpretList(get(res, 'data', []));
				} catch {
					toast.error('Something went wrong');
				} finally {
					setIsLoading(false);
				}
			})();
		}
	}, [isOpen, search]);

	const _renderForm = () => {
		return (
			<div className="border-t border-solid border-gray-100 px-8 py-4">
				<Label1 className="text-center">
					No one fitted yet? Leave your contact, we will send you more expert’s
					information.
				</Label1>
				<div className="-mx-4 my-4 grid grid-cols-12">
					<FormInput
						className="col-span-4 px-4 xs:col-span-12"
						label="Your name"
						control={control}
						name="name"
						placeholder="Type your name here"
						rules={{ required: true }}
					/>
					<FormInput
						className="col-span-4 px-4 xs:col-span-12"
						label="Your email"
						type="email"
						control={control}
						name="email"
						placeholder="Type your email here"
						rules={{ required: true }}
					/>
					<FormInput
						className="col-span-4 px-4 xs:col-span-12"
						label="Your phone number"
						control={control}
						name="phone"
						type="tel"
						placeholder="Type your phone here"
					/>
					<FormInput
						className="col-span-12 px-4"
						label={
							<Label2>
								Why dont you find a suitable expert?{' '}
								<span className="font-normal text-gray-600">
									(Ex: not related, too many experts to choose...)
								</span>
							</Label2>
						}
						control={control}
						name="extra.reason"
						placeholder="Type something"
						helper="We're only going to send you newly expert and support you, no spam whatsover!"
					/>
				</div>

				<div className="mt-4 flex justify-center">
					<Button
						onClick={onClose}
						className="mr-2 px-8"
						kind={KindButton.SECONDARY}
						rounded
					>
						Cancel
					</Button>
					<Button
						onClick={handleSubmit(onSubmit)}
						disabled={formState.isSubmitting}
						className="ml-2 px-8"
						kind={KindButton.BRAND}
						rounded
					>
						Submit
					</Button>
				</div>
			</div>
		);
	};

	const _renderBanner = () => {
		return (
			<div className="my-32 flex flex-1 flex-col items-center justify-center p-6">
				<Image src={Banner} width={400} height={290} alt="xyz" />
				<div className="mt-6 max-w-xl text-center">
					<H4 className="text-gray-800" bold>
						Pixie{' '}
						<span className="relative inline-block">
							got
							<span className="absolute -top-4 left-4 ">
								<AcuteSolid />
							</span>
						</span>{' '}
						you! We will mail you in a minute
					</H4>
					<Label2 className="text-gray-500">
						In the Meantime, you can explore all experts on our platform
					</Label2>
				</div>

				<div className="mt-4 flex justify-center">
					<Button
						className="mr-2 px-8"
						kind={KindButton.SECONDARY}
						rounded
						onClick={onClose}
					>
						Later
					</Button>
					<Button
						disabled={formState.isSubmitting}
						className="ml-2 px-8"
						kind={KindButton.BRAND}
						rounded
						onClick={handleGoToExpertList}
					>
						Go
					</Button>
				</div>
			</div>
		);
	};

	const _renderFooter = () => {
		return (
			<div className="flex items-center justify-between rounded bg-white px-8 py-8">
				<div>
					<div className="flex items-center">
						<H3 semiBold>Explore Pixie’s top experts in</H3>
						<H3 semiBold className="ml-2 capitalize text-brand-500">
							{search}
						</H3>
					</div>

					{openNewTab && (
						<Body3 semiBold className="text-green-600">
							Feel free to explore. It will open in new tab so you won&apos;t leave
							this webinar
						</Body3>
					)}
				</div>

				<Link passHref href="/experts" legacyBehavior>
					<a target={openNewTab ? '_blank' : '_self'} onClick={onClickSeeAll}>
						<Button rounded size={SizeButton.LARGE} rightIcon={<ArrowRight2Outline />}>
							{`See more${openNewTab ? ' in new tab' : ''}`}
						</Button>
					</a>
				</Link>
			</div>
		);
	};

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-[100] overflow-y-auto"
				onClose={disableClickOutsideToClose ? () => {} : onClose}
			>
				<Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

				<div className="min-h-screen px-4 text-center xs:px-0">
					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div
							style={{
								minHeight: compactMode ? 400 : height.current,
								maxWidth: 1320,
								overflowY: 'auto',
								zIndex: 9999,
								background: gradient
									? 'linear-gradient(277.22deg, #87F2FB -3.38%, #FFCA89 93.08%)'
									: 'white'
							}}
							id="model"
							className="relative my-8 inline-block w-full transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all xs:my-0 xs:h-screen xs:rounded-none"
						>
							<Dialog.Title
								as="div"
								className="flex flex-row-reverse items-center justify-between px-6 py-4 text-center text-lg font-medium leading-6 text-gray-900"
							>
								<Button
									onClick={onClose}
									className="h-10 w-10"
									kind={KindButton.GHOST}
								>
									<CloseOutline size={20} />
								</Button>
								<H4 bold className="text-gray-700">
									Recommended experts
								</H4>
								<div />
							</Dialog.Title>

							<div className="mt-2">
								{!isSummited && _renderExpret()}
								{!isSummited && !hideForm && _renderForm()}
								{isSummited && _renderBanner()}
							</div>
							{showFooter && _renderFooter()}
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default RecommentExpretModal;
