/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-useless-escape */
import { Body4, Textbox } from '@pixiepkg/react';
import { Controller, FieldValues } from 'react-hook-form';
import FormContainer from './FormContainer';
import styles from './styles/FormControl.module.scss';

// type FormInputProps<TFieldValues> = IFormControlBase<
// 	ComponentProps<typeof Textbox>,
// 	TFieldValues
// > & {
// 	type?: 'text' | 'email' | 'tel' | 'date' | 'password' | 'number';
// 	addonLeft?: JSX.Element | string;
// 	showErrorMessage?: boolean;
// };

const FormInput = ({
	control,
	name,
	containerProps,
	controlProps,
	defaultValue,
	label,
	rules,
	className,
	isRequired,
	helper,
	placeholder,
	type = 'text',
	addonLeft,
	showErrorMessage = false
}: FieldValues) => {
	const emailRegex =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
	const pattern = type === 'email' ? emailRegex : type === 'tel' ? phoneRegex : undefined;

	return (
		<Controller
			name={name}
			defaultValue={defaultValue}
			rules={{
				pattern: pattern,
				...rules
			}}
			control={control}
			render={({ field: { value, onChange, ...field }, fieldState: { invalid, error } }) => (
				<FormContainer
					className={className}
					label={label}
					isRequired={typeof rules?.required == 'boolean' ? rules?.required : isRequired}
					helper={helper}
					{...containerProps}
				>
					<Textbox
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						value={value || ''}
						invalid={invalid}
						onChange={onChange}
						placeholder={placeholder}
						{...{ ...controlProps, ...field }}
						addonLeft={addonLeft}
						// @ts-ignore
						type={type == 'tel' ? 'number' : type}
						className={type == 'tel' ? styles.inputphone : ' '}
					/>
					{showErrorMessage && <Body4 color="#f56565">{error && error.message}</Body4>}
				</FormContainer>
			)}
		/>
	);
};

export default FormInput;
