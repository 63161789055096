import { Body4, Textarea } from '@pixiepkg/react';
import classNames from 'classnames';
import { Controller, FieldValues } from 'react-hook-form';
import FormContainer from './FormContainer';

// type FormTextAreaProps<TFieldValues extends FieldValues> = IFormControlBase<
// 	ComponentProps<typeof Textarea>,
// 	TFieldValues
// >;

const FormTextArea = ({
	control,
	name,
	containerProps,
	controlProps,
	defaultValue,
	label,
	rules,
	className,
	isRequired,
	placeholder
}: FieldValues) => {
	return (
		<Controller
			name={name}
			defaultValue={defaultValue}
			rules={rules}
			control={control}
			render={({ field: { value, onChange, ...field }, fieldState: { invalid, error } }) => (
				<FormContainer
					className={classNames(className, 'relative')}
					label={label}
					error={error?.message}
					isRequired={typeof rules?.required == 'boolean' ? rules?.required : isRequired}
					{...containerProps}
				>
					{typeof rules?.maxLength == 'object' && value !== undefined && (
						<Body4
							className={classNames(
								'absolute right-0 -translate-y-full text-gray-400',
								{
									'text-red-400': value.length > rules.maxLength.value
								}
							)}
						>
							{value.length || 0}/{rules.maxLength.value}
						</Body4>
					)}
					<Textarea
						invalid={invalid}
						setValue={onChange}
						value={value || ''}
						rows={3}
						placeholder={placeholder}
						{...{ ...controlProps, ...field }}
					/>
				</FormContainer>
			)}
		/>
	);
};

export default FormTextArea;
