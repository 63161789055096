import React, { Fragment, useEffect, useState } from 'react';
import s from '@components/Select/styles/select.module.scss';
import { Listbox, Transition } from '@headlessui/react';
import { ArrowDownOutline } from '@pixiepkg/icon';
import classNames from 'classnames';
import { Body3 } from '@pixiepkg/react';

export interface ItemSelect {
	label: string;
	key: any;
}

interface SelectProps {
	items: Array<ItemSelect>;
	valueSelected?: ItemSelect;
	onChangeSelected: (item: ItemSelect) => void;

	disabled?: boolean;
}

// eslint-disable-next-line react/display-name
export const Select = React.forwardRef<HTMLDivElement, SelectProps>((props: SelectProps, ref) => {
	const { items, valueSelected, onChangeSelected, disabled } = props;
	const [selected, setSelected] = useState(valueSelected);

	useEffect(() => {
		setSelected(valueSelected);
	}, [valueSelected]);

	return (
		<Listbox
			value={selected}
			onChange={(item: ItemSelect) => {
				if (item) {
					const changedItem = items.find((i) => i.key === item.key);
					setSelected(changedItem);
					changedItem && onChangeSelected?.(changedItem);
				}
			}}
			disabled={disabled}
		>
			<div className="relative mt-1" ref={ref}>
				<Listbox.Button
					className={classNames(
						'w-full text-left',
						s.button,
						disabled ? s.buttonInactive : s.buttonActive
					)}
					// disabled={disabled}
				>
					<Body3 medium>{selected?.label}</Body3>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<ArrowDownOutline size={18} />
					</span>
				</Listbox.Button>
				<Transition
					as={Fragment}
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Listbox.Options className="focus:outline-none absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm">
						{items.map((item: ItemSelect) => (
							<Listbox.Option
								key={item.key}
								className={({ active }: any) =>
									`${active ? 'bg-gray-800 text-gray-50' : 'text-gray-900'}
                          relative cursor-default select-none px-4 py-2`
								}
								value={item}
							>
								{({ selected }: any) => (
									<>
										<span
											className={`${
												selected ? 'font-medium' : 'font-normal'
											} block truncate`}
										>
											{item.label}
										</span>
									</>
								)}
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Transition>
			</div>
		</Listbox>
	);
});
